@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.editor-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.menuBar {
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  background: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.menuBar button {
  border: 1px solid #e0e0e0;
  background: white;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #344767;
  transition: all 0.2s ease;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuBar button:hover {
  background-color: #f1f4ff;
  border-color: #1a73e8;
  color: #1a73e8;
}

.menuBar button.is-active {
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: white;
}

.ProseMirror {
  padding: 24px;
  min-height: 600px;
  outline: none;
  font-size: 16px;
  line-height: 1.6;
  color: #344767;
}

.ProseMirror p {
  margin: 1em 0;
}

.ProseMirror h1 {
  font-size: 2em;
  margin: 1em 0 0.5em;
  font-weight: 600;
}

.ProseMirror h2 {
  font-size: 1.5em;
  margin: 1em 0 0.5em;
  font-weight: 600;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-left: 2em;
  margin: 1em 0;
}

.ProseMirror li {
  margin: 0.5em 0;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Text alignment styles */
.ProseMirror .text-left {
  text-align: left;
}

.ProseMirror .text-center {
  text-align: center;
}

.ProseMirror .text-right {
  text-align: right;
}

/* Dark mode support */
[data-theme='dark'] .editor-wrapper {
  background: #1a2035;
  border-color: #2d3456;
}

[data-theme='dark'] .menuBar {
  background: #1a2035;
  border-color: #2d3456;
}

[data-theme='dark'] .menuBar button {
  background: #202940;
  border-color: #2d3456;
  color: #fff;
}

[data-theme='dark'] .menuBar button:hover {
  background: #2d3456;
  border-color: #1a73e8;
}

[data-theme='dark'] .ProseMirror {
  color: #fff;
}

[data-theme='dark'] .ProseMirror p.is-editor-empty:first-child::before {
  color: #8392ab;
}

.ProseMirror .heading-one {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #1a73e8;
  margin: 1em 0 0.5em;
  line-height: 1.2;
}

.ProseMirror .heading-two {
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
  font-weight: 700;
  color: #344767;
  margin: 1em 0 0.5em;
  line-height: 1.3;
}
